import moment from 'moment'
import mergeAdvanced from "object-merge-advanced";

var HashMap = require('hashmap');
var parser = require("number-parsing");
const formatDecimal = require('format-decimal');
const _ = require('loadsh')


const INITIAL_STATE = {

    is_project_selected: false,
    geninfo: {},
    followers: [],
    is_project_ok: false,
    is_info_project_get: false,
    is_project_ready_render: false,
    selected_project: {},
    selected_date: null,
    active_projects: true,
    all_projects: false,
    projects_list : [],
    flag_get_projects: false,
    info_project: {},
    colonne_maturato: [],
    colonne_previsionato: [],
    start_date: '',
    end_date: '',
    base64_xls: null,
    xls_generated: false,
    minmaxdate_maturato: [],
    minmaxdate_prevsionato: [],
    righe_scheda: [],
    to_update_previsionato_day:  new HashMap(),
    to_write_previsionato_day:  new HashMap(),
    to_update_previsionato_expense: new HashMap(),
    to_write_previsionato_expense: new HashMap(),
    to_delete_previsonato_exp_days: [],
    empscontracts_list: [],
    pm_last_update: null,
    disable_save: false,
    loading: false
 
}

const currentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOGOUT':
            return {
                ...INITIAL_STATE
            }
        case 'ONLY_ACTIVE_PROJECTS':
            return {
                ...state,
                active_projects: true,
                all_projects: false,
                flag_get_projects: false
            }
        case 'ALL_PROJECTS':
            return {
                ...state,
                active_projects: false,
                all_projects: true,
                flag_get_projects: false
            }
        case 'UPDATE_GENINFO':
            var part_geninfo = action.part_geninfo
            return {
                ...state,
                geninfo: {
                    ...state.geninfo,
                    part_geninfo
                }
            }

        case 'XLS_DOWNLOADED':
            return {
                ...state,
                xls_generated: false,
                base64_xls: null
            }

        case 'CREATE_XLS_SCHEDA_FULFILLED':
            return {
                ...state,
                xls_generated: true,
                base64_xls: action.payload
            }

        case 'CREATE_XLS_SCHEDA_REJECTED':
            return {
                ...state,
                xls_generated: false,
                base64_xls: null
            }
            
        case 'UPDATE_CURRENT_PROJECT':

            var start_date = ''
            var end_date = ''

            if(action.payload.x_studio_start_date_project != false){
                start_date = action.payload.x_studio_start_date_project
            } 
            if(action.payload.x_studio_end_date_project != false){
                end_date = action.payload.x_studio_end_date_project
            }

            return {
                ...state,
                selected_project: action.payload,
                start_date: start_date,
                end_date: end_date,
                is_project_selected: true
            }
        case 'UPDATE_CURRENT_DATE':
            return {
                ...state,
                selected_date: action.payload,
            }

        case 'SET_START_DATE':

            var disable_save = false

            if(state.end_date === ''){
                disable_save = true
            }

            return {
                ...state,
                start_date: action.date,
                disable_save: disable_save
            }

        case 'SET_END_DATE':

            var disable_save = false
   
            if(state.start_date === ''){
                disable_save = true
            }

            // modifico le colonne visibili
            var editstate = {...state}
            // se maggiore dell'attuale end date
            if(moment(action.date) > moment(editstate.end_date)){
                var dateStart = moment(state.end_date).add(1,'months')
                var dateEnd = moment(action.date);
                var interim = dateStart.clone();
                var timeValues = [];
                
                while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
                   timeValues.push(interim.format('YYYY-MM-01'));
                   interim.add(1,'month');
                }

                timeValues.map((date) => {
                    editstate.colonne_previsionato.push({
                        key: 'date_' + moment(date).format('YYYY-MM-01'),
                        name: '' + moment(date).format('MMM YY'),
                        editable: true,
                        width: '1%'
                    },)
                })
            }
            // se minore dell'attuale end date
            if(moment(action.date) < moment(editstate.end_date)){
                var dateStart = moment(action.date).add(1,'months');
                var dateEnd = moment(state.end_date).add(1,'months');
                var interim = dateStart.clone();
                var timeValues = [];    
                
                while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
                   timeValues.push(interim.format('YYYY-MM-01'));
                   interim.add(1,'month');
                }

                timeValues.map((date) => {
                    var keyid = 'date_' + moment(date).format('YYYY-MM-01')
                    editstate.colonne_previsionato.map((obj,index) => { 
                        if(obj.key === keyid) {
                            editstate.colonne_previsionato.splice(index,1)
                        }
                    })
                })
            }

            return {
                ...editstate,
                end_date: action.date,
                colonne_previsionato: editstate.colonne_previsionato,
                disable_save: disable_save
            }
            
        case 'SENDED_PROJECT':
            return {
                ...state,
                is_project_ok: true,
                loading: true
            }
        case 'GET_PROJECTS_LIST_FULFILLED':
            return {
                ...state,
                flag_get_projects: true,
                projects_list: action.payload
            }
        case 'GET_LAST_UPDATE_FULFILLED':
            return {
                ...state,
                pm_last_update: action.payload
            }       
        case 'GET_INFO_PROJECT_FULFILLED':
            return {
                ...state,
                is_info_project_get: true,
                info_project: action.payload,
                loading: false
            }
        case 'GET_INFO_EMPCONTRACTS_FULFILLED':
            return {
                ...state,
                empscontracts_list: action.payload,
            }

        case 'GET_FOLLOWERS_PROJECT_FULFILLED':
            return {
                ...state,
                followers: action.payload
            }

        case 'ADD_FOLLOWER_PROJECT_FULFILLED':
            var followers = state.followers
            followers.push(action.payload)
            return {
                ...state,
                followers: followers
            }

        case 'REMOVE_FOLLOWER_PROJECT_FULFILLED':
            var followers = state.followers.filter(item => item !== action.payload)
            return {
                ...state,
                followers: followers
            }
       
        case 'PROJECT_READY_TO_RENDER':

            var disable_save = false

            if(state.start_date === '' || state.end_date === ''){
                disable_save = true
            }


            return {
                ...state,
                is_project_ready_render: true,
                colonne_maturato: action.colonne_maturato,
                colonne_previsionato: action.colonne_previsionato,
                righe_scheda: action.righe,
                to_update_previsionato_day:  action.previsionato_day,
                to_update_previsionato_expense: action.previsionato_expense,
                minmaxdate_maturato: action.minmaxdate_maturato,
                minmaxdate_prevsionato: action.minmaxdate_prevsionato,
                disable_save: disable_save
            }

        case 'DELETE_RECORD_PREVISIONATO':
            var editstate = {...state}
            var newidsdelete = state.to_delete_previsonato_exp_days
            newidsdelete.push(...action.ids)
            newidsdelete = [...new Set(newidsdelete)]

            action.keyids.map((keyid) => {
                delete editstate.righe_scheda[keyid]
            })


            return {
                ...editstate,
                to_delete_previsonato_exp_days: newidsdelete,
            }

        case 'UPDATE_COLONNE_SCHEDA':
            return {
                ...state,
            }
            
        case 'RESET_SCHEDA_COMMESSA':
            return  {
                ...state,
                is_project_selected: false,
                is_project_ok: false,
                is_info_project_get: false,
                is_project_ready_render: false,
                selected_project: {},
                selected_date: null,
                active_projects: true,
                all_projects: false,
                projects_list : [],
                flag_get_projects: false,
                info_project: {},
                colonne_maturato: [],
                colonne_previsionato: [],
                righe_scheda: [],
                geninfo: {},
                to_update_previsionato_day:  new HashMap(),
                to_write_previsionato_day:  new HashMap(),
                to_update_previsionato_expense: new HashMap(),
                to_write_previsionato_expense: new HashMap(),
                to_delete_previsonato_exp_days: [],
            }

        case 'ADDEMP_SCHEDACOMMESSA':
            var editstate = {...state}
            if (editstate.righe_scheda[action.empinfo.keyid] === undefined) {
                editstate.righe_scheda[action.empinfo.keyid] = action.empinfo
            }
          // aggiungo la riga si somma

             var temp_edit_sommatotale = editstate.righe_scheda['somma_verticale']

             delete editstate.righe_scheda['somma_verticale']
             editstate.righe_scheda['somma_verticale'] = {}
             editstate.righe_scheda['somma_verticale']['consulente'] = '-- Somma Totale --'
             editstate.righe_scheda['somma_verticale']['keyid'] = 'somma_verticale'
             editstate.righe_scheda['somma_verticale']['giorni_timesheet_previsto'] = 0
             editstate.righe_scheda['somma_verticale']['costo_timesheet_previsto'] = 0
             editstate.righe_scheda['somma_verticale']['costo_trasferta_previsto'] = 0
             editstate.righe_scheda['somma_verticale']['giorni_timesheet_mese'] = 0
             editstate.righe_scheda['somma_verticale']['costo_trasferta_maturato'] = temp_edit_sommatotale['costo_trasferta_maturato']
             editstate.righe_scheda['somma_verticale']['costo_timesheet_mese'] =  temp_edit_sommatotale['costo_timesheet_mese']
              
             Object.entries(editstate.righe_scheda).map((value, index, array) => {
                 if(value[0] === 'somma_verticale'){
                     value = value[1]
                     editstate.righe_scheda['somma_verticale']['costo_timesheet_previsto'] = formatDecimal(editstate.righe_scheda['somma_verticale']['costo_timesheet_previsto'], {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                     editstate.righe_scheda['somma_verticale']['costo_trasferta_previsto'] = formatDecimal(editstate.righe_scheda['somma_verticale']['costo_trasferta_previsto'], {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                
                 } else {
                     // cambio e formatto i decimali
                     var valuex = value[1]

                     // ciclo le date
                     Object.keys(valuex).map((prop, index, array) => {
                       // controllo se la property è una data
                           if(prop.includes('date_')){
                
                               // controllo se esiste la proprieta altrimenti aggiungo e sommo
                               if( editstate.righe_scheda['somma_verticale'][prop] === undefined){
                                editstate.righe_scheda['somma_verticale'][prop] = 0
                                editstate.righe_scheda['somma_verticale'][prop] = parser(editstate.righe_scheda['somma_verticale'][prop]) + parser(valuex[prop])
                               } else {
                                editstate.righe_scheda['somma_verticale'][prop] = parser(editstate.righe_scheda['somma_verticale'][prop]) + parser(valuex[prop])
                               }
                
                           }
                  
                       })
                    
                     if(editstate.righe_scheda[value[0]]['costo_timesheet_previsto'] != undefined){
                       editstate.righe_scheda['somma_verticale']['costo_timesheet_previsto'] = parser(editstate.righe_scheda['somma_verticale']['costo_timesheet_previsto']) + parser(valuex.costo_timesheet_previsto)
                       editstate.righe_scheda[value[0]]['costo_timesheet_previsto'] = formatDecimal(parser(editstate.righe_scheda[value[0]]['costo_timesheet_previsto']), {decimal: ',',precision: 2,thousands: '.'}) + ' €'
            
                     }
                     if(editstate.righe_scheda[value[0]]['costo_trasferta_previsto'] != undefined){
                       editstate.righe_scheda['somma_verticale']['costo_trasferta_previsto'] = parser(editstate.righe_scheda['somma_verticale']['costo_trasferta_previsto']) + parser(valuex.costo_trasferta_previsto)
                       editstate.righe_scheda[value[0]]['costo_trasferta_previsto'] = formatDecimal(parser(editstate.righe_scheda[value[0]]['costo_trasferta_previsto']), {decimal: ',',precision: 2,thousands: '.'}) + ' €'
            
                     }
  
                     if(editstate.righe_scheda[value[0]]['giorni_timesheet_previsto'] != undefined){
                       editstate.righe_scheda['somma_verticale']['giorni_timesheet_previsto'] = formatDecimal(parser(editstate.righe_scheda['somma_verticale']['giorni_timesheet_previsto']) + parser(valuex.giorni_timesheet_previsto), {decimal: ',',precision: 2,thousands: '.'})
                     }
            
                     if(editstate.righe_scheda[value[0]]['giorni_timesheet_mese'] != undefined){
                       editstate.righe_scheda['somma_verticale']['giorni_timesheet_mese'] = formatDecimal(parser(editstate.righe_scheda['somma_verticale']['giorni_timesheet_mese']) + parser(valuex.giorni_timesheet_mese), {decimal: ',',precision: 2,thousands: '.'})
                     }
                 }
             })

            return {
                ...editstate
            }
        case 'RECALC_SOMMA_VERTICALE':
            return state

        case 'GET_INFO_PROJECT_RESET':
            return  {
                ...state,
                is_project_ready_render: false,
                is_info_project_get: false,
                colonne_maturato: [],
                colonne_previsionato: [],
                righe_scheda: [],
                geninfo: {},
                to_update_previsionato_day:  new HashMap(),
                to_write_previsionato_day:  new HashMap(),
                to_update_previsionato_expense: new HashMap(),
                to_write_previsionato_expense: new HashMap(),
                to_delete_previsonato_exp_days: [],
                loading: true
            }
        case 'UPDATE_ALL_PREVISIONATO_DATA':
            return {
                ...state,
                disable_save: true
            }

        case 'UPDATE_ALL_PREVISIONATO_DATA_PENDING':
            return {
                ...state,
                disable_save: true
            }

        case 'UPDATE_ALL_PREVISIONATO_DATA_FULFILLED':
            return  {
                ...state,
                disable_save: false,
                is_project_ready_render: false,
                is_info_project_get: false,
                colonne_maturato: [],
                colonne_previsionato: [],
                righe_scheda: [],
                to_update_previsionato_day:  new HashMap(),
                to_write_previsionato_day:  new HashMap(),
                to_update_previsionato_expense: new HashMap(),
                to_write_previsionato_expense: new HashMap(),
                geninfo: {},
                to_delete_previsonato_exp_days: [],
                loading: true
            }

        case 'HANDLE_EDIT_CELLS':
            var editstate = {...state}
            var db_action = action.edit_data
            var keyid = (action.edit_data.cellKey + action.edit_data.fromRowData.consulente + action.edit_data.fromRowData.profilo).split(' ').join('')



            if(action.edit_data.fromRowData.keyid != 'somma_verticale'){
                if(isNaN(parser(action.edit_data.updated[action.edit_data.cellKey])) === false){

                
                //Update RigheSalvate nello State
                try {
                    if(action.edit_data.cellKey == 'costo_trasferta_previsto'){
                        editstate.righe_scheda[action.edit_data.fromRowData.keyid]['costo_trasferta_previsto'] = parser(editstate.righe_scheda[action.edit_data.fromRowData.keyid]['costo_trasferta_previsto']) + parser(action.edit_data.updated[action.edit_data.cellKey])
                        editstate.righe_scheda[action.edit_data.fromRowData.keyid][action.edit_data.cellKey] = parser(action.edit_data.updated[action.edit_data.cellKey])
                    } else {
                        if (editstate.righe_scheda[action.edit_data.fromRowData.keyid][action.edit_data.cellKey] === undefined) {
                        // non esisteva quindi aggiungo il numero al previsionato timesheet giorni totalione
                        editstate.righe_scheda[action.edit_data.fromRowData.keyid]['giorni_timesheet_previsto'] = parser(editstate.righe_scheda[action.edit_data.fromRowData.keyid]['giorni_timesheet_previsto']) + parser(action.edit_data.updated[action.edit_data.cellKey])
                        } else {
                            //  ne aggiungo la differenza che può anche essere negativa e quindi una sottrazione
                            editstate.righe_scheda[action.edit_data.fromRowData.keyid]['giorni_timesheet_previsto'] = parser(editstate.righe_scheda[action.edit_data.fromRowData.keyid]['giorni_timesheet_previsto']) + (parser(action.edit_data.updated[action.edit_data.cellKey]) - parser(editstate.righe_scheda[action.edit_data.fromRowData.keyid][action.edit_data.cellKey]))
                        }
                        editstate.righe_scheda[action.edit_data.fromRowData.keyid]['costo_timesheet_previsto'] = parser(editstate.righe_scheda[action.edit_data.fromRowData.keyid]['costo_profilo']) * parser(editstate.righe_scheda[action.edit_data.fromRowData.keyid]['giorni_timesheet_previsto'])
                        editstate.righe_scheda[action.edit_data.fromRowData.keyid][action.edit_data.cellKey] = parser(action.edit_data.updated[action.edit_data.cellKey])
                    }
                } catch (e) {
                    
                }

                  // aggiungo la riga si somma

                  var temp_edit_sommatotale = editstate.righe_scheda['somma_verticale']

                  delete editstate.righe_scheda['somma_verticale']
                  editstate.righe_scheda['somma_verticale'] = {}
                  editstate.righe_scheda['somma_verticale']['consulente'] = '-- Somma Totale --'
                  editstate.righe_scheda['somma_verticale']['keyid'] = 'somma_verticale'
                  editstate.righe_scheda['somma_verticale']['giorni_timesheet_previsto'] = 0
                  editstate.righe_scheda['somma_verticale']['costo_timesheet_previsto'] = 0
                  editstate.righe_scheda['somma_verticale']['costo_trasferta_previsto'] = 0
                  editstate.righe_scheda['somma_verticale']['giorni_timesheet_mese'] = 0
                  editstate.righe_scheda['somma_verticale']['costo_trasferta_maturato'] = temp_edit_sommatotale['costo_trasferta_maturato']
                  editstate.righe_scheda['somma_verticale']['costo_timesheet_mese'] =  temp_edit_sommatotale['costo_timesheet_mese']
  
                  Object.entries(editstate.righe_scheda).map((value, index, array) => {
                      if(value[0] === 'somma_verticale'){
                          value = value[1]
                          editstate.righe_scheda['somma_verticale']['costo_timesheet_previsto'] = formatDecimal(editstate.righe_scheda['somma_verticale']['costo_timesheet_previsto'], {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                          editstate.righe_scheda['somma_verticale']['costo_trasferta_previsto'] = formatDecimal(editstate.righe_scheda['somma_verticale']['costo_trasferta_previsto'], {decimal: ',',precision: 2,thousands: '.'}) + ' €'
  
                      } else {
                          // cambio e formatto i decimali
                          var valuex = value[1]

                          // ciclo le date
                          Object.keys(valuex).map((prop, index, array) => {
                            // controllo se la property è una data
                                if(prop.includes('date_')){
                     
                                    // controllo se esiste la proprieta altrimenti aggiungo e sommo
                                    if( editstate.righe_scheda['somma_verticale'][prop] === undefined){
                                        editstate.righe_scheda['somma_verticale'][prop] = 0
                                        editstate.righe_scheda['somma_verticale'][prop] = parser(editstate.righe_scheda['somma_verticale'][prop]) + parser(valuex[prop])
                                    } else {
                                        editstate.righe_scheda['somma_verticale'][prop] = parser(editstate.righe_scheda['somma_verticale'][prop]) + parser(valuex[prop])
                                    }
                     
                                }
                       
                            })

                          if(editstate.righe_scheda[value[0]]['costo_timesheet_previsto'] != undefined){
                            editstate.righe_scheda['somma_verticale']['costo_timesheet_previsto'] = parser(editstate.righe_scheda['somma_verticale']['costo_timesheet_previsto']) + parser(valuex['costo_timesheet_previsto'])
                            editstate.righe_scheda[value[0]]['costo_timesheet_previsto'] = formatDecimal(parser(editstate.righe_scheda[value[0]]['costo_timesheet_previsto']), {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                          }

                          if(editstate.righe_scheda[value[0]]['costo_trasferta_previsto'] != undefined){
                            editstate.righe_scheda['somma_verticale']['costo_trasferta_previsto'] = parser(editstate.righe_scheda['somma_verticale']['costo_trasferta_previsto']) + parser(valuex['costo_trasferta_previsto'])
                            editstate.righe_scheda[value[0]]['costo_trasferta_previsto'] = formatDecimal(parser(editstate.righe_scheda[value[0]]['costo_trasferta_previsto']), {decimal: ',',precision: 2,thousands: '.'}) + ' €'
                          }

                          if(editstate.righe_scheda[value[0]]['giorni_timesheet_previsto'] != undefined){
                            editstate.righe_scheda['somma_verticale']['giorni_timesheet_previsto'] = formatDecimal(parser(editstate.righe_scheda['somma_verticale']['giorni_timesheet_previsto']) + parser(valuex.giorni_timesheet_previsto), {decimal: ',',precision: 2,thousands: '.'})
                          }
                 
                          if(editstate.righe_scheda[value[0]]['giorni_timesheet_mese'] != undefined){
                            editstate.righe_scheda['somma_verticale']['giorni_timesheet_mese'] = formatDecimal(parser(editstate.righe_scheda['somma_verticale']['giorni_timesheet_mese']) + parser(valuex.giorni_timesheet_mese), {decimal: ',',precision: 2,thousands: '.'})
                          }
                      }
                  })
            
            
                if(action.edit_data.cellKey === 'costo_trasferta_previsto'){
                    // Modifica previsionato Expense
                    if(editstate.to_update_previsionato_expense.has(keyid)){
                        editstate.to_update_previsionato_expense.set(keyid,{
                            "id": editstate.to_update_previsionato_expense.get(keyid).id,
                            "x_studio_project": [state.selected_project.id, state.selected_project.name],
                            "x_studio_employee":  action.edit_data.fromRowData.employee_id,
                            "x_studio_expenses": action.edit_data.updated[action.edit_data.cellKey],
                            "x_studio_daily_rate": action.edit_data.fromRowData.costo_profilo,
                            "x_studio_profile": action.edit_data.fromRowData.profilo,
                            "x_studio_user_last_update": action.edit_data.fromRowData.user_id,
                            "x_studio_type": "Expenses"
                        })
                    } else {
                        editstate.to_write_previsionato_expense.set(keyid,{
                            "x_studio_project": [state.selected_project.id, state.selected_project.name],
                            "x_studio_employee":  action.edit_data.fromRowData.employee_id,
                            "x_studio_expenses": action.edit_data.updated[action.edit_data.cellKey],
                            "x_studio_daily_rate": action.edit_data.fromRowData.costo_profilo,
                            "x_studio_profile": action.edit_data.fromRowData.profilo,
                            "x_studio_user_last_update": action.edit_data.fromRowData.user_id,
                            "x_studio_type": "Expenses"
                        })
                    }
                } else {
                    // Modifica previsionato Giorni
                    if(editstate.to_update_previsionato_day.has(keyid)){
                        editstate.to_update_previsionato_day.set(keyid,
                            {
                                "id": editstate.to_update_previsionato_day.get(keyid).id,
                                "x_studio_date": action.edit_data.cellKey.replace('date_',''),
                                "x_studio_days": action.edit_data.updated[action.edit_data.cellKey],
                                "x_studio_profile": action.edit_data.fromRowData.profilo,
                                "x_studio_daily_rate": action.edit_data.fromRowData.costo_profilo,
                                "x_studio_project": [state.selected_project.id, state.selected_project.name],
                                "x_studio_employee":  action.edit_data.fromRowData.employee_id,
                                "x_studio_user_last_update": action.edit_data.fromRowData.user_id,
                                "x_studio_type": "Days"
                                                })
                                        } else  {
                                            editstate.to_write_previsionato_day.set(keyid,
                                                {
                                "x_studio_date": action.edit_data.cellKey.replace('date_',''),
                                "x_studio_days": action.edit_data.updated[action.edit_data.cellKey],
                                "x_studio_profile": action.edit_data.fromRowData.profilo,
                                "x_studio_daily_rate": action.edit_data.fromRowData.costo_profilo,
                                "x_studio_project": [state.selected_project.id, state.selected_project.name],
                                "x_studio_employee": action.edit_data.fromRowData.employee_id,
                                "x_studio_user_last_update": action.edit_data.fromRowData.user_id,
                                "x_studio_type": "Days"
                            })        
                    }
                }
              }
            }

            return editstate

        default:
            return state
    }
}

export default currentReducer